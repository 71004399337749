import DotsDropdown from '@/components/element/dropdowns/DotsDropdown';
import Spinner from '@/components/element/loading/Spinner';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { QUEST_URL } from '@/config';
import { useCampaignActions } from '@/features/campaigns/hooks/report/useCampaignActions';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { useCampaignWithdrawFunds } from '@/features/campaigns/hooks/report/useCampaignWithdrawFunds';
import {
	getCampaign,
	updateCampaignStatus,
} from '@/features/campaigns/services/campaigns.service';
import {
	CampaignRewardCategory,
	CampaignRewardType,
	ICampaign,
	ReportTabs,
	Status,
} from '@/features/campaigns/types';
import EnterpriseApprovalProcess from '@/features/dashboard/components/verify/EnterpriseApprovalProcess';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { EnterpriseApprovalStage } from '@/features/dashboard/types/enterprise.types';
import { useGetGlobalConfigs } from '@/features/getting-started/hooks/useGetGlobalConfigs';
import { useAuth } from '@/hooks/useAuth';
import { queryClient } from '@/lib/react-query';
import { adjustedIntercomLauncherPosition } from '@/lib/utils';
import { handleErrorMessage } from '@/utils/notifications';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import RestartCampaign from '../../../campaign/RestartCampaign';
import ShareCampaign from '../../../campaign/ShareCampaign';
import CampaignLaunchDialog from '../../../create-campaign/launch/CampaignLaunchDialog';
import { Media } from '@/components/element/media/Media';
import { asset } from '@/config/asset-cdn';
import useCreditsOverview from '@/features/intract-credits/hooks/useCreditsOverview';
import {
	CreditRequestTypeEnum,
	DataExportTypeEnum,
} from '@/features/intract-credits/types/credits.enums';
import { ICreditBenefit } from '@/features/intract-credits/types/credits.types';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import ConfirmExportDialog from './ConfirmExportDialog';
import IntroducingBoosters from '../../../boosters/introduction/IntroducingBoostersDialog';

const CampaignActions = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { campaign, id, link, selectedTab } = useCampaignReport();
	const { enterprise } = useGetEnterprise();
	const { user } = useAuth();
	const { navigate } = useRouter();

	const [openLaunch, setOpenLaunch] = useState(false);
	const [openRestart, setOpenRestart] = useState(false);
	const [openShare, setOpenShare] = useState(
		searchParams.get('share-celebrate') === 'true' ? true : false,
	);
	const [show, setShow] = useState(false);
	const [showBoosters, setShowBoosters] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);

	useCampaignWithdrawFunds();

	const { globalConfigs } = useGetGlobalConfigs();

	const disclaimerToastIdRef = useRef(null);
	const {
		isLoading,
		convertToDraft,
		duplicateCampaign,
		deleteCampaign,
		downloadLeaderboard,
		downloadWinners,
		downloadSubmissions,
		restartCampaign,
		handleUnschedule,
		isUnScheduleLoading,
		handleDataExport,
		isDeductingCredits,
		openExportConfirm,
		closeExportConfirmation,
		handleDataExportWrapper,
	} = useCampaignActions();

	const { creditBenefits } = useCreditsOverview();

	const leaderboardExportBenefit = useMemo(() => {
		return (
			Array.isArray(creditBenefits?.consumeCredits) &&
			creditBenefits?.consumeCredits?.find(
				(i: ICreditBenefit) =>
					i.requestType === CreditRequestTypeEnum.LeaderBoardExport,
			)
		);
	}, [creditBenefits]);

	useEffect(() => {
		if (searchParams.get('share-celebrate') === 'true') {
			setOpenShare(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams.get('share-celebrate')]);

	const canRestart = useMemo(() => {
		if (campaign?.status === Status.Completed) {
			const isTokenReward = campaign.reward.find(
				(i) => i.category === CampaignRewardCategory.Token,
			);
			const isLuckyDraw = campaign.reward.find((i) =>
				[
					CampaignRewardType.Leaderboard,
					CampaignRewardType.SmartRaffle,
					CampaignRewardType.LuckyDraw,
				].includes(i.method),
			);
			if (!isTokenReward && !isLuckyDraw) return true;
			return false;
		}
		return false;
	}, [campaign]);

	const menuOptions = [
		{
			type: 'item',
			label: 'Share Quest',
			onClick: () => setOpenShare(true),
		},
		{
			type: 'item',
			label: 'Duplicate Quest',
			onClick: () => duplicateCampaign(id),
		},
		...(canRestart
			? [
					{
						type: 'item',
						label: 'Restart Quest',
						onClick: () => setOpenRestart(true),
					},
				]
			: []),
		...(campaign?.status === Status.Scheduled
			? [
					{
						type: 'item',
						label: 'Unschedule Quest',
						onClick: () => handleUnschedule(id),
					},
				]
			: []),
		{
			type: 'separator',
		},
		{
			type: 'item',
			label: 'Delete Quest',
			onClick: () => deleteCampaign(id),
		},
	];

	const setCampaignToTestMode = async () => {
		if (campaign?.status === Status.InReview) {
			window.open(`${QUEST_URL}/quest/${id}`, '_blank');
		} else {
			await updateCampaignStatus(id, {
				newStatus: Status.InReview,
			});
		}
	};

	const showLaunchDisclaimer = () => {
		const campaignCompletionCriteriaMet =
			globalConfigs?.completionBeforeLaunch === 0 ||
			(campaign?.completedJourneysCount ?? 0) >=
				(globalConfigs?.completionBeforeLaunch ?? 0);

		adjustedIntercomLauncherPosition('140px');

		/**
		 * community approved toast is not required now,
		 * but keeping it for future reference
		 * instead, direct pop-up will be shown
		 *  */
		disclaimerToastIdRef.current = toast.warning(
			!campaignCompletionCriteriaMet
				? `You need to complete all tasks on your campaign at least once before launch`
				: 'You need to get your community approved before you can launch the campaign',
			{
				action: (
					<Button
						onClick={() => {
							!campaignCompletionCriteriaMet
								? setCampaignToTestMode()
								: setShow(true);
							toast.dismiss(disclaimerToastIdRef.current);
							adjustedIntercomLauncherPosition('20px');
						}}
						variant="outline"
					>
						{'Continue'}
					</Button>
				),
				closeButton: true,
				onDismiss: () => adjustedIntercomLauncherPosition('20px'),
				onAutoClose: () => adjustedIntercomLauncherPosition('20px'),
				className: 'w-[23.25rem]',
			},
		);
	};

	const getUpdatedCampaign = async () => {
		setIsRefetching(true);
		try {
			const updatedCampaign: ICampaign = await getCampaign(id);

			queryClient.setQueryData(['campaign', id], updatedCampaign);

			const isCampaignCompletionCriteriaMet =
				globalConfigs?.completionBeforeLaunch === 0 ||
				(campaign?.completedJourneysCount ?? 0) >=
					(globalConfigs?.completionBeforeLaunch ?? 0);

			const isEnterpriseApproved = enterprise?.isApproved;
			const isApprovalCompleted =
				enterprise?.approvalStage === EnterpriseApprovalStage.Completed;
			const isAnyLoading = Object?.values(isLoading)?.some((i) => i);

			const isLaunchDisabled =
				isAnyLoading || !isCampaignCompletionCriteriaMet;

			const isApprovalRequired = !(
				isEnterpriseApproved || isApprovalCompleted
			);

			if (isLaunchDisabled) {
				showLaunchDisclaimer();
			} else if (isApprovalRequired) {
				//show enterprise approval dialog
				setShow(true);
			} else {
				setOpenLaunch(true);
			}
		} catch (error) {
			handleErrorMessage(error);
		} finally {
			setIsRefetching(false);
		}
	};

	if (!campaign) return <></>;
	return (
		<>
			{campaign?.status === Status.InReview && (
				<>
					<Button
						variant="outline"
						onClick={() => convertToDraft(id, campaign?.status)}
						disabled={Object.values(isLoading).some((i) => i)}
					>
						{isLoading.convertToDraft ? (
							<Spinner className="me-2" />
						) : (
							<i className="bi-pencil-square me-2"></i>
						)}
						Edit Quest
					</Button>

					<Button onClick={getUpdatedCampaign} disabled={isRefetching}>
						<i
							className={`bi-${isRefetching ? 'arrow-repeat animate-spin' : 'rocket-takeoff'} me-2`}
						></i>
						<span>Launch Quest</span>
					</Button>
				</>
			)}

			{campaign?.status !== Status.InReview &&
				selectedTab !== ReportTabs.Winners &&
				selectedTab !== ReportTabs.Submissions && (
					<>
						<Button
							onClick={() => {
								handleDataExportWrapper({
									shouldDeductCredits:
										!campaign?.dataExport?.isLeaderboardPaid,
									campaignId: campaign?._id,
									campaignName: campaign?.name,
									dataExportType: DataExportTypeEnum.Leaderboard,
									requiredCredits:
										leaderboardExportBenefit?.credits,
								});
							}}
							variant="outline"
							disabled={isDeductingCredits || isLoading.exporting}
						>
							{isDeductingCredits || isLoading.exporting ? (
								<Spinner className="me-2" />
							) : (
								<i className="bi-download me-2"></i>
							)}
							<span className="flex items-center gap-2">
								Download Leaderboard
								{campaign?.dataExport?.isLeaderboardPaid ? null : (
									<div className="flex items-center gap-1 bg-slate-100 rounded-full">
										<Media
											src={asset['credit-icon-primary']}
											altText="credit-icon"
											className="size-[20px]"
										/>
										<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-0.5 pr-2">
											{leaderboardExportBenefit?.credits}
										</span>
									</div>
								)}
							</span>
						</Button>
					</>
				)}
			{campaign?.status === Status.Active && (
				<Button
					onClick={() => {
						setShowBoosters(true);
					}}
				>
					<i className="bi-graph-up me-2"></i>
					<span>Boost Your Quest</span>
				</Button>
			)}
			{campaign?.status !== Status.InReview &&
				campaign?.status === Status.Completed &&
				selectedTab === ReportTabs.Winners && (
					<>
						<Button
							onClick={() => {
								handleDataExportWrapper({
									shouldDeductCredits:
										!campaign?.dataExport?.isWinnersPaid,
									campaignId: campaign?._id,
									campaignName: campaign?.name,
									dataExportType: DataExportTypeEnum.Winners,
									requiredCredits:
										leaderboardExportBenefit?.credits,
								});
							}}
							variant="outline"
							disabled={isDeductingCredits || isLoading.exporting}
						>
							{isDeductingCredits || isLoading.exporting ? (
								<Spinner className="me-2" />
							) : (
								<i className="bi-download me-2"></i>
							)}
							<span className="flex items-center gap-2">
								Download Winners
								{campaign?.dataExport?.isWinnersPaid ? null : (
									<div className="flex items-center gap-1 bg-slate-100 rounded-full">
										<Media
											src={asset['credit-icon-primary']}
											altText="credit-icon"
											className="size-[20px]"
										/>
										<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-0.5 pr-2">
											{leaderboardExportBenefit?.credits}
										</span>
									</div>
								)}
							</span>
						</Button>
					</>
				)}
			{campaign?.status !== Status.InReview &&
				selectedTab === ReportTabs.Submissions && (
					<>
						<Button
							onClick={() => {
								downloadSubmissions(campaign?._id);
							}}
							variant="outline"
						>
							{isLoading.exporting ? (
								<Spinner className="me-2" />
							) : (
								<i className="bi-download me-2"></i>
							)}
							<span>Download Submissions</span>
						</Button>
					</>
				)}
			<TooltipWrapper tooltip="Check how your quest looks" align="end">
				<Button
					variant="outline"
					size="icon"
					className=""
					onClick={() => {
						window.open(link, '_blank');
					}}
				>
					<i className="bi bi-box-arrow-up-right"></i>
				</Button>
			</TooltipWrapper>
			{isUnScheduleLoading ? (
				<Spinner />
			) : (
				<DotsDropdown options={menuOptions} className="w-[180px]" />
			)}
			{campaign?.status === Status.InReview && (
				<CampaignLaunchDialog
					open={openLaunch}
					setOpen={setOpenLaunch}
					campaignId={campaign?._id}
					tokenReward={campaign?.reward?.find(
						(i) => i.category === CampaignRewardCategory.Token,
					)}
					startImmediately={campaign?.startImmediately}
					showRewardInfo={campaign?.reward?.length > 0}
				/>
			)}
			<ShareCampaign
				open={openShare}
				setOpen={() => {
					setOpenShare(false);
					setSearchParams({});
				}}
				isCelebrate={
					searchParams.get('share-celebrate') === 'true' ? true : false
				}
				link={link}
			/>
			<RestartCampaign
				open={openRestart}
				setOpen={setOpenRestart}
				campaign={campaign}
			/>
			<EnterpriseApprovalProcess
				open={show}
				setOpen={setShow}
				enterpriseDetails={enterprise}
			/>
			<IntroducingBoosters open={showBoosters} setOpen={setShowBoosters} />
			<ConfirmExportDialog
				open={openExportConfirm}
				onClose={() => closeExportConfirmation()}
				onSubmit={() =>
					handleDataExport({
						shouldDeductCredits:
							!campaign?.dataExport?.[
								openExportConfirm === DataExportTypeEnum?.Leaderboard
									? 'isLeaderboardPaid'
									: 'isWinnersPaid'
							],
						campaignId: campaign?._id,
						campaignName: campaign?.name,
						dataExportType: openExportConfirm,
						requiredCredits: leaderboardExportBenefit?.credits,
					})
				}
				isPending={isLoading.exporting || isDeductingCredits}
				leaderboardExportCredits={leaderboardExportBenefit?.credits}
			/>
		</>
	);
};

export default CampaignActions;
