import { http, createConfig } from 'wagmi';
import {
	mainnet,
	sepolia,
	polygon,
	polygonMumbai,
	arbitrum,
	linea,
	polygonZkEvmTestnet,
	polygonZkEvm,
	optimism,
	mode,
	base,
	astarZkEVM,
	zkSync,
	opBNB,
	opBNBTestnet,
	arbitrumGoerli,
	zoraSepolia,
	zora,
	avalanche,
	bsc,
	bscTestnet,
	fantom,
	baseGoerli,
	manta,
	mantaTestnet,
	scroll,
	fuse,
	fuseSparknet,
	scrollSepolia,
	modeTestnet,
	optimismGoerli,
	blast,
	blastSepolia,
	mantle,
	berachainTestnet,
	iotex,
	flare,
	confluxESpace,
	kroma,
	rootstock,
	zetachain,
	telos,
	coreDao,
	arbitrumSepolia,
} from 'wagmi/chains';
import { xlayer } from '@/utils/chains/xlayer';
import { bitlayer } from '@/utils/chains/bitlayer';
import { dojima } from '@/utils/chains/dojima';
import { bSquared } from '@/utils/chains/bSquared';
import { capx } from '@/utils/chains/capX';
import { bob } from '@/utils/chains/bob';
import { tabiTestnet } from '@/utils/chains/tabiTestnet';
import { humanode } from '@/utils/chains/humanode';
import { viction } from '@/utils/chains/viction';
import { mint } from '@/utils/chains/mint';
import { beraBArtioTestnet } from '@/utils/chains/beraBArtio';
import { neonMainnet } from '@/utils/chains/neonMainnet';
import { seiNetwork } from '@/utils/chains/sei';
import { xProtocolTestnet } from '@/utils/chains/xProtocolTestnet';
import { soneiumTestnet } from '@/utils/chains/soneiumTestnet';
import { cronosZkEVM } from '@/utils/chains/cronosZkEvm';
import { proofOfPlayBoss } from '@/utils/chains/proofOfPlayBoss';
import { cronosZkevmTestnet } from '@/utils/chains/cronosZkevmTestnet';

import { injected } from 'wagmi/connectors';
import { abstractTestnet } from '@/utils/chains/abstractTestnet';
import { uniChainSepoliaTestnet } from '@/utils/chains/unichainSepoliaTestnet';
import { inkSepolia } from '@/utils/chains/inkSepoliaTestnet';
import { apeChain } from '@/utils/chains/apeChain';

export const wagmiConfig = createConfig({
	chains: [
		mainnet,
		sepolia,
		polygon,
		linea,
		arbitrum,
		polygonMumbai,
		polygonZkEvmTestnet,
		polygonZkEvm,
		mode,
		base,
		optimism,
		astarZkEVM,
		zkSync,
		opBNB,
		opBNBTestnet,
		arbitrumGoerli,
		zoraSepolia,
		zora,
		avalanche,
		bsc,
		bscTestnet,
		fantom,
		mantle,
		baseGoerli,
		manta,
		mantaTestnet,
		scroll,
		fuse,
		fuseSparknet,
		bitlayer,
		scrollSepolia,
		modeTestnet,
		optimismGoerli,
		blast,
		blastSepolia,
		xlayer,
		dojima,
		berachainTestnet,
		iotex,
		bSquared,
		flare,
		capx,
		confluxESpace,
		kroma,
		bob,
		tabiTestnet,
		rootstock,
		humanode,
		mint,
		viction,
		beraBArtioTestnet,
		neonMainnet,
		seiNetwork,
		xProtocolTestnet,
		zetachain,
		telos,
		soneiumTestnet,
		cronosZkEVM,
		proofOfPlayBoss,
		coreDao,
		abstractTestnet,
		uniChainSepoliaTestnet,
		inkSepolia,
		apeChain,
		cronosZkevmTestnet,
		arbitrumSepolia,
	],
	connectors: [injected()],
	transports: {
		[mainnet.id]: http(),
		[sepolia.id]: http(),
		[polygon.id]: http(),
		[linea.id]: http(),
		[arbitrum.id]: http(),
		[polygonMumbai.id]: http(),
		[polygonZkEvmTestnet.id]: http(),
		[polygonZkEvm.id]: http(),
		[mode.id]: http(),
		[base.id]: http(),
		[optimism.id]: http(),
		[astarZkEVM.id]: http(),
		[zkSync.id]: http(),
		[opBNB.id]: http(),
		[opBNBTestnet.id]: http(),
		[arbitrumGoerli.id]: http(),
		[zoraSepolia.id]: http(),
		[zora.id]: http(),
		[avalanche.id]: http(),
		[bsc.id]: http(),
		[bscTestnet.id]: http(),
		[fantom.id]: http(),
		[baseGoerli.id]: http(),
		[manta.id]: http(),
		[mantaTestnet.id]: http(),
		[scroll.id]: http(),
		[fuse.id]: http(),
		[fuseSparknet.id]: http(),
		[scrollSepolia.id]: http(),
		[modeTestnet.id]: http(),
		[bitlayer.id]: http(),
		[optimismGoerli.id]: http(),
		[blast.id]: http(),
		[blastSepolia.id]: http(),
		[mantle.id]: http(),
		[xlayer.id]: http(),
		[dojima.id]: http(),
		[berachainTestnet.id]: http(),
		[iotex.id]: http(),
		[bSquared.id]: http(),
		[flare.id]: http(),
		[capx.id]: http(),
		[confluxESpace.id]: http(),
		[kroma.id]: http(),
		[bob.id]: http(),
		[tabiTestnet.id]: http(),
		[rootstock.id]: http(),
		[humanode.id]: http(),
		[mint.id]: http(),
		[viction.id]: http(),
		[beraBArtioTestnet.id]: http(),
		[neonMainnet.id]: http(),
		[seiNetwork.id]: http(),
		[xProtocolTestnet.id]: http(),
		[zetachain.id]: http(),
		[telos.id]: http(),
		[soneiumTestnet.id]: http(),
		[cronosZkEVM.id]: http(),
		[proofOfPlayBoss.id]: http(),
		[coreDao.id]: http(),
		[abstractTestnet.id]: http(),
		[uniChainSepoliaTestnet.id]: http(),
		[inkSepolia.id]: http(),
		[apeChain.id]: http(),
		[cronosZkevmTestnet.id]: http(),
		[arbitrumSepolia.id]: http(),
	},
} as any);
