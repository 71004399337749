import InputSelect from '@/components/element/inputs/InputSelect';
import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { EnterpriseTokenStatus } from '@/features/dashboard/types/enterprise.types';
import { useEffect } from 'react';
import { useCreateEnterprise } from '../../hooks/useCreateEnterprise';
import { tokenStatus } from '../../types/onboarding-options';

const CreateEnterpriseDialog = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
}) => {
	const { community, setCommunity, isLoading, errors, handleNext, setErrors } =
		useCreateEnterprise(setOpen);

	useEffect(() => {
		setErrors({
			name: false,
			twitter: false,
		});
	}, [community.name, community.twitter, setErrors]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[550px] overflow-visible">
				<DialogHeader className="border-b pb-3 mb-4">
					<DialogTitle>Create new Project</DialogTitle>
					<DialogDescription>
						Please fill in the details below to create a new project.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-6">
					<InputText
						label="Project Name"
						required={true}
						placeholder="Uniswap"
						value={community.name}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								name: e,
							}))
						}
						error={errors.name}
						errorText="Please enter your project name"
						className="text-left"
					/>
					<InputText
						label="Project Twitter"
						required={true}
						placeholder="intractCampaign"
						prepend={
							<div className="ps-3 pe-2 flex items-center text-xs">
								<i className="bi bi-twitter text-twitter pe-2"></i>
								<span className="text-muted-foreground font-medium">
									https://twitter.com/
								</span>
							</div>
						}
						value={community.twitter}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								twitter: e,
							}))
						}
						errorText="Please enter your twitter username"
						error={errors.twitter}
						className="text-left"
					/>
					<InputSelect
						label="What is your token status?"
						placeholder="Select token status"
						options={tokenStatus}
						value={community.tokenStatus}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								tokenStatus: e,
							}))
						}
						errorText="Please select a category"
						className="text-left"
						placeholderClassName="!text-muted-foreground"
					/>
					{community.tokenStatus === EnterpriseTokenStatus.Listed ? (
						<div className="flex gap-3 w-full">
							<InputText
								label="Chain Id"
								placeholder="1234"
								value={community?.tokenDetails?.tokenChainId}
								setValue={(e) => {
									const numericValue = Number(e);
									if (isNaN(numericValue)) {
										return;
									}
									setCommunity((p) => ({
										...p,
										tokenDetails: {
											...p.tokenDetails,
											tokenChainId: numericValue,
										},
									}));
								}}
								className="text-left w-[20%]"
							/>
							<InputText
								label="Chain"
								required
								placeholder="base"
								value={community?.tokenDetails?.tokenChain}
								setValue={(e) => {
									setCommunity((p) => ({
										...p,
										tokenDetails: {
											...p.tokenDetails,
											tokenChain: e,
										},
									}));
								}}
								className="text-left w-[20%]"
							/>
							<InputText
								label="Token Address"
								required
								placeholder="0xb851220202C019d1645CB9f75dcc5ef7a66aaca9"
								value={community?.tokenDetails?.tokenAddress}
								setValue={(e) =>
									setCommunity((p) => ({
										...p,
										tokenDetails: {
											...p.tokenDetails,
											tokenAddress: e,
										},
									}))
								}
								className="text-left w-[80%]"
							/>
						</div>
					) : null}
				</div>

				<DialogFooter className="justify-between space-x-2 border-t pt-3 mt-4">
					<Button variant="ghost" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button onClick={() => handleNext()} disabled={isLoading}>
						{isLoading ? 'Creating...' : 'Continue'}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CreateEnterpriseDialog;
