import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import RewardTokenSelect from '../../create-campaign/rewards/components/input/RewardTokenSelect';
import { cn } from '@/lib/utils';
import { DialogTitle } from '@/components/ui/dialog';

const SpeedrunEnrollmentPostActive = ({
	tokens,
	tokenChains,
	speedRunConfig,
	setSpeedRunConfig,
	errors,
}) => {
	return (
		<div>
			<div className="mb-8">
				<DialogTitle className={cn(`text-2xl mb-1 `)}>
					Introducing Speedrun
				</DialogTitle>
				<div className="text-sm text-muted-foreground">
					Boosters are a new way to increase your quest visibility and
					reach.
				</div>
			</div>
			<div>
				<div className="grid grid-cols-2 gap-4 mb-8">
					<div>
						<div className="text-sm font-medium mb-2">Select Chain</div>
						<SelectNetwork2
							value={speedRunConfig.chain.chainId?.toString()}
							setValue={({ chainId, chain, namespaceTag }) => {
								setSpeedRunConfig((p: any) => ({
									...p,
									chain: {
										chain,
										namespaceTag,
										chainId: +chainId,
									},
								}));
							}}
							allowedChainIds={
								tokenChains?.map((chain: any) =>
									chain?.chainId?.toString(),
								) ?? []
							}
							errorMsg="Please select a network"
							defaultAll={false}
							error={errors?.chain}
						/>
					</div>
					<div>
						<div className="text-sm font-medium mb-2">Select Token</div>
						<RewardTokenSelect
							tokens={tokens}
							value={speedRunConfig?.token.tokenAddress}
							setValue={(address: string) => {
								const token = tokens.find(
									(i: any) => i.address === address,
								);
								setSpeedRunConfig((p: any) => ({
									...p,
									token: {
										tokenAddress: token.address,
										tokenSymbol: token.symbol,
										tokenDecimals: token.decimals,
										logo: token.logo,
										name: token.name,
										chain: token.chain,
										namespaceTag: token.namespaceTag,
									},
								}));
							}}
							error={errors?.tokenAddress}
							errorText="Please select a token"
						/>
					</div>
				</div>
				<div>
					<div className="text-sm mb-3 font-medium">
						How much Tokens do you want to allocate for Speedrun?
					</div>
					<div className="grid grid-cols-3 gap-5">
						{[0.001, 0.002, 0.003].map((i) => (
							<RadioItem
								isChecked={speedRunConfig?.amount === i}
								label={`$${i}`}
								icon="bi-code-square"
								description="Upto 1k more participation"
								onClick={() =>
									setSpeedRunConfig((p: any) => ({
										...p,
										amount: i,
									}))
								}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpeedrunEnrollmentPostActive;

const RadioItem = ({ isChecked, label, icon, description, onClick }) => {
	return (
		<div
			className={cn(
				isChecked
					? 'border-violet-500 border border-opacity-80'
					: 'border border-slate-300',
				`p-2 rounded-lg bg-white cursor-pointer relative`,
			)}
			onClick={onClick}
		>
			<div className="absolute top-0 end-0 p-2">
				<i
					className={cn(
						isChecked ? 'bi-check-circle-fill' : `bi-circle`,
						isChecked ? 'text-violet-500' : `text-slate-300 `,
					)}
				/>
			</div>
			<div className=" font-medium">{label}</div>
		</div>
	);
};
