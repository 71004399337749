import { useState } from 'react';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { useGetAllTokensForEnterprise } from '@/features/campaigns/hooks/tokens/useGetAllTokens';
import { IdentityNamespaceTag } from '@/features/campaigns/types';
import { useCreateTxn } from '@/hooks/useCreateTxn';
import { useReadContracts } from '@/hooks/useReadContracts';
import { useAccount } from 'wagmi';
import erc20Abi from '@/config/abi/ERC20ABI.json';
import { SPEEDRUN_USER_ADDRESS } from '@/config';
import { ethers } from 'ethers';
import {
	enrollCampaignInSpeedRunPostActive,
	updateSpeedrunTransferTxn,
} from '../../services/campaigns.service';
import { useCampaignReport } from './useCampaignReport';
import { handleErrorMessage } from '@/utils/notifications';
import { queryClient } from '@/lib/react-query';

export const useCampaignBoosters = ({ step, setStep }) => {
	const { id } = useCampaignReport();
	const [speedRunConfig, setSpeedRunConfig] = useState({
		amount: 100,
		token: {
			tokenAddress: '',
			tokenSymbol: '',
			tokenDecimals: '0',
			logo: '',
			name: '',
			chain: 0,
			namespaceTag: '',
		},
		chain: {
			chainId: 137,
			chain: 'polygon',
			namespaceTag: IdentityNamespaceTag.EvmEvm,
		},
	});
	const [errors, setErrors] = useState({
		tokenAddress: false,
		chain: false,
	});
	const { tokenChains } = useGetSupportedChains({});
	const { tokens } = useGetAllTokensForEnterprise(
		speedRunConfig.chain.chain,
		speedRunConfig.chain.namespaceTag,
	);
	const [isLoading, setIsLoading] = useState(false);
	const { address } = useAccount();
	const [userBalance, setUserBalance] = useState(0);
	const { readContract } = useReadContracts();
	const { startTxn } = useCreateTxn();

	const enrollInSpeedRun = async () => {
		try {
			if (step === 'speedrun.introduction') {
				setStep('speedrun.enrollment');
				return;
			}
			setIsLoading(true);
			const errors_ = { tokenAddress: false, chain: false };
			if (!speedRunConfig?.token?.tokenAddress) {
				errors_.tokenAddress = true;
			}
			if (!speedRunConfig?.chain?.chain) {
				errors_.chain = true;
			}
			setErrors(errors_);
			if (errors_.tokenAddress || errors_.chain) {
				setIsLoading(false);
				return;
			}
			await enrollCampaignInSpeedRunPostActive(id, {
				tokenAddress: speedRunConfig.token.tokenAddress,
				chain: speedRunConfig.chain.chain,
				namespaceTag: speedRunConfig.chain.namespaceTag,
				tokenAmount: speedRunConfig?.amount,
			});
			const userBalance_ = await readContract({
				chainId: speedRunConfig?.chain?.chainId,
				contractAddress: speedRunConfig?.token?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'balanceOf',
				args: [address],
			});
			console.log('userBalance_ ', userBalance_);
			setUserBalance(+userBalance_?.toString());

			const speedrunAddress = SPEEDRUN_USER_ADDRESS;
			const requiredAmount = ethers.utils.parseUnits(
				speedRunConfig.amount.toString(),
				speedRunConfig.token.tokenDecimals,
			);
			if (!ethers.BigNumber.from(userBalance).gte(requiredAmount)) {
				throw new Error(
					'Insufficient balance, please top up with required token',
				);
			}
			console.log('requiredAmount', requiredAmount);
			const txn = await startTxn({
				chainId: speedRunConfig?.chain?.chainId,
				contractAddress: speedRunConfig?.token?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'transfer',
				args: [speedrunAddress, requiredAmount],
			});
			console.log(txn);
			if (!txn) {
				throw new Error(
					'Transfer Transaction failed, please reach out to support',
				);
			}
			await updateSpeedrunTransferTxn(id, {
				txHash: txn.transactionHash,
				status: 'completed',
				chainId: speedRunConfig?.chain?.chainId,
				receipt: txn.receipt,
				chain: speedRunConfig?.chain?.chain,
				namespaceTag: speedRunConfig?.chain?.namespaceTag,
			});
			await queryClient.invalidateQueries({
				queryKey: ['campaign', id],
			});
			setIsLoading(false);
			setStep('speedrun.complete');
		} catch (err) {
			handleErrorMessage(err);
			console.log(err);
			setIsLoading(false);
		}
	};

	return {
		tokens,
		tokenChains,
		speedRunConfig,
		setSpeedRunConfig,
		isLoading,
		enrollInSpeedRun,
		errors,
		userBalance,
	};
};
